// import React from 'react';

// const EditEnquirypage = () => {
//   return <div>EditEnquirypage</div>;
// };

// export default EditEnquirypage;

import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { UserManager } from '../../../@types/user';
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { useDispatch } from 'react-redux';
import { USER1 } from '../../../redux/slices/calendar';
import Switch from '@mui/material/Switch';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<UserManager, 'imageUrl'> {
  imageUrl: any;
}

export default function EditEnquirypage() {
  const dispatch = useDispatch();

  const { id = '' } = useParams();
  const { pathname } = useLocation();

  const isEdit = pathname.includes('edit');

  const [userData, setUserData] = useState<any>([]);

  useEffect(() => {
    getUserdata();
  }, []);

  const getUserdata = async () => {
    // const accessToken: any = localStorage.getItem('accessToken');
    // const response = await axios.get(`/users/${id}`, {
    //   headers: {
    //     'x-auth-token': accessToken,
    //     'content-type': 'application/json',
    //   },
    // });
    // const data = await response.data.data;
    // dispatch(USER1(data));
    // setUserData(data);
  };

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    firstName: Yup.string().required('Fisrt Name is required'),
    lastName: Yup.string().required(' Last Name is required'),
    email: Yup.string().required('Email is required').email(),
    username: Yup.string().required('Username is required'),
    isBlock: Yup.boolean(),
    role: Yup.string(),
    imageUrl: Yup.mixed().test('required', 'Avatar is required', (value) => value !== ''),
  });

  const defaultValues = useMemo(
    () => ({
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      username: userData?.username || '',
      imageUrl: userData?.imageUrl || '',
      isBlock: userData?.isBlock || false,
      role: userData?.role === 'admin' ? true : false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && userData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, userData]);

  const [updateData, setupdateUserData] = useState([]);

  const onSubmit = async (data: FormValuesProps) => {
    let formData = new FormData();
    formData.append('email', data?.email);
    formData.append('firstName', data?.firstName);
    formData.append('lastName', data?.lastName);
    formData.append('username', data?.username);
    formData.append('image', data?.imageUrl);
    if (data?.isBlock == true && data.role === 'true') {
      formData.append('isBlock', data?.isBlock);
      formData.append('role', 'user');
    } else if (data?.isBlock == true && data.role === 'false') {
      formData.append('isBlock', data?.isBlock);
      formData.append('role', 'user');
    } else if (data?.isBlock == false && data.role === 'true') {
      formData.append('isBlock', data?.isBlock);
      formData.append('role', 'admin');
    } else if (data?.isBlock == false && data.role === 'false') {
      formData.append('isBlock', data?.isBlock);
      formData.append('role', 'user');
    }
    // if (data.role === 'true') {
    //   formData.append('role', 'admin');
    // } else {
    //   formData.append('role', 'user');
    // }

    try {
      const accessToken: any = localStorage.getItem('accessToken');

      const response = await axios.put(`/users/${id}`, formData, {
        headers: {
          'x-auth-token': accessToken,
          'content-type': 'application/json',
        },
      });
      const getdata = await response.data.data;

      setupdateUserData(getdata);
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(PATH_DASHBOARD.user.root);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue('imageUrl', Object.assign(file, { preview: URL.createObjectURL(file) }));
      }
    },
    [setValue]
  );

  const handleSwitch = useCallback(
    (checked: boolean) => {
      setValue('isBlock', checked);
    },
    [setValue]
  );
  const handleAdmin = useCallback(
    (checked: boolean) => {
      setValue('role', checked);
    },
    [setValue]
  );

  return (
    <>
      {userData && (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid style={{ display: 'grid', padding: '5rem' }}>
            {/* <Grid item xs={12} md={4}>
              <Card sx={{ py: 5, px: 3 }}>
                <Box sx={{ mb: 5 }}>
                  <RHFUploadAvatar
                    name="imageUrl"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png,
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />
                </Box>
              </Card>
            </Grid> */}

            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    },
                  }}
                >
                  <RHFTextField name="username" label="User Name" />
                  <RHFTextField name="firstName" label="First Name" />
                  <RHFTextField name="lastName" label="Last Name" />
                  <RHFTextField name="email" label="Email Address" />
                  {/* <Stack direction="row">
                    <Switch
                      name="isBlock"
                      checked={values?.isBlock}
                      onChange={(event) => handleSwitch(event.target.checked)}
                    />
                    <Typography sx={{ margin: ' 6px 4px' }}>
                      {values?.isBlock ? 'Blocked User' : 'Unblocked User'}
                    </Typography>
                  </Stack> */}
                  {/* {!values.isBlock ? (
                    <Stack direction="row">
                      <Switch
                        name="role"
                        checked={values?.role}
                        onChange={(event) => handleAdmin(event.target.checked)}
                      />
                      <Typography sx={{ margin: ' 6px 4px' }}>
                        {values?.role ? 'Admin' : 'User'}
                      </Typography>
                    </Stack>
                  ) : null} */}
                </Box>

                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {!isEdit ? 'Create User' : 'Update User'}
                  </LoadingButton>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
