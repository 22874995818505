// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  class: getIcon('ic_classroom'),
  dashboard: getIcon('ic_dashboard'),
  mail: getIcon('ic_mail'),
  Notification: getIcon('mdi:bell-notification'),
  enquiry: getIcon('ic_enquiry'),
};

const navConfig = [
  {
    subheader: 'Activities',
    items: [
      {
        title: 'App Activities',
        path: PATH_DASHBOARD.general.app,
        icon: <Icon icon="carbon:application-web" />,
      },
    ],
  },
  {
    subheader: 'Features & Functionality',
    items: [
      {
        title: 'All User',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [{ title: 'User List', path: PATH_DASHBOARD.user.root }],
      },
      // CLASS
      {
        title: 'class',
        path: PATH_DASHBOARD.class.root,
        icon: ICONS.class,
        children: [
          { title: 'list', path: PATH_DASHBOARD.class.list },
          { title: 'create', path: PATH_DASHBOARD.class.new },
        ],
      },
      // SHOP
      {
        title: 'shop',
        path: PATH_DASHBOARD.shop.root,
        icon: <Icon icon="mdi:shop-plus" />,
        children: [
          { title: 'list', path: PATH_DASHBOARD.shop.list },
          { title: 'create', path: PATH_DASHBOARD.shop.new },
        ],
      },
      // TESTIMONIAL
      {
        title: 'testimonial',
        path: PATH_DASHBOARD.Testimonials.root,
        icon: <Icon icon="ph:user-list-fill" />,
        children: [
          { title: 'list', path: PATH_DASHBOARD.Testimonials.list },
          { title: 'create', path: PATH_DASHBOARD.Testimonials.new },
        ],
      },

      // EVENTS
      {
        title: 'event',
        path: PATH_DASHBOARD.Event.root,
        icon: <Icon icon="material-symbols:event-available-rounded" />,
        children: [
          { title: 'list', path: PATH_DASHBOARD.Event.list },
          { title: 'create', path: PATH_DASHBOARD.Event.new },
        ],
      },
      // Invite
      {
        title: 'Invite other users',
        path: PATH_DASHBOARD.InviteUser.root,
        icon: ICONS.mail,
        children: [{ title: 'Invite', path: PATH_DASHBOARD.InviteUser.list }],
      },
      // Notification
      {
        title: 'Send Notification',
        path: PATH_DASHBOARD.Notification.root,
        icon: <Icon icon="mdi:bell-notification" />,
        children: [{ title: 'Notification', path: PATH_DASHBOARD.Notification.list }],
      },
      // Enquiry
      {
        title: 'Enquiry Section',
        path: PATH_DASHBOARD.enquiryemail.root,
        icon: ICONS.enquiry,
        children: [{ title: 'Enquiry Section', path: PATH_DASHBOARD.enquiryemail.list }],
      },
    ],
  },
];

export default navConfig;
