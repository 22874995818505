import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import axios from '../utils/axios';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      localStorage.clear();

      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const email = localStorage.getItem('userEmail');
        const password = localStorage.getItem('userPassword');
        // const pushSubscription = localStorage.getItem('SubscribtionID');
        const getpushSubscription: any = localStorage?.getItem('SubscribtionID');
        const pushSubscription = JSON.parse(getpushSubscription);

        if (accessToken) {
          setSession(accessToken);
          const response = await axios.post('/users/login', {
            email,
            password,
          });
          // eslint-disable-next-line

          const { user } = response.data;

          // const response = await axios.get('/api/account/my-account');
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // const { enqueueSnackbar } = useSnackbar();
  const login = async (email: string, password: string, pushSubscription?: any) => {
    // ===================================
    // =====================================
    // if (pushSubscription !== {''}) {

    const response = await axios.post('/users/login', {
      email,
      password,
      pushSubscription,
    });
    const { user } = response.data;
    if (response?.data?.data?.role === 'admin') {
      const accessToken = response.data.token;
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userPassword', password);
      localStorage.setItem('SubscribtionID', JSON.stringify(pushSubscription));
      localStorage.setItem('role', response?.data?.data?.role);

      setSession(accessToken);

      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });
    } else {
      localStorage.setItem('role', response?.data?.data?.role);
    }

    // =======================
  };
  // };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    userName: string,

    image: any
  ) => {
    let formData = new FormData();

    formData.append('email', email);
    formData.append('password', password);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('username', userName);
    // formData.append('image', image);
    if (image) {
      formData.append('image', image);
    }
    formData.append('role', 'admin');

    const response = await axios.post('/users/create', formData);

    const { user } = response.data;
    const accessToken = response.data.token;

    setSession(accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
