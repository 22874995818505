import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import logoMain from '../img/afrofit.png';
import logoMain1 from '../img/afrofit1.png';

import useCollapseDrawer from '../hooks/useCollapseDrawer';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // -------------------------------------------------------
  // const logo = <Box sx={{ width: 70, height: 60, ...sx }}></Box>;

  const logo =
    isCollapse === true ? (
      <Box sx={{ width: 70, height: 60, ...sx }}>
        <img src={logoMain1} alt="afrofit" width="100%" height="100%" />
      </Box>
    ) : (
      <Box sx={{ width: 180, height: 70, ...sx }}>
        <img src={logoMain} alt="afrofit" width="100%" height="100%" />
      </Box>
    );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
