import { createSlice } from '@reduxjs/toolkit';
// utils
// @types
import { CalendarState } from '../../@types/calendar';
//

// ----------------------------------------------------------------------

const initialState: CalendarState = {
  Users: [],
  User1: [],
  EventData: [],
};

export const slice = createSlice({
  name: 'calendar',
  initialState,

  reducers: {
    USERS: (state, action) => {
      state.Users = action.payload;
    },
    USER1: (state, action) => {
      state.User1 = action.payload;
    },
    EventData: (state, action) => {
      state.EventData = action.payload;
    },
  },
});

// Reducer

export const { USERS, USER1, EventData } = slice.actions;

export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
