import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import EditEnquirypage from 'src/pages/dashboard/EnquiryEmail/EditEnquirypage';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();
  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
          ],
        },
        { path: 'Userlist', element: <UserList /> },
        {
          path: 'class',
          children: [
            {
              element: <Navigate to="/dashboard/class/list" replace />,
              index: true,
            },
            { path: 'list', element: <ClassList /> },
            { path: 'new', element: <ClassCreate /> },
            { path: ':id/edit', element: <ClassCreate /> },
            { path: 'view/:id', element: <ClassDetail /> },
          ],
        },
        {
          path: 'shop',
          children: [
            {
              element: <Navigate to="/dashboard/shop/list" replace />,
              index: true,
            },
            { path: 'list', element: <ShopList /> },
            { path: 'new', element: <ShopCreate /> },
            { path: ':id/edit', element: <ShopCreate /> },
            { path: 'view/:id', element: <ShopDetail /> },
          ],
        },
        {
          path: 'testimonials',
          children: [
            {
              element: <Navigate to="/dashboard/testimonials/list" replace />,
              index: true,
            },
            { path: 'list', element: <TestimonialList /> },
            { path: 'new', element: <TestimonialCreate /> },
            { path: ':id/edit', element: <TestimonialCreate /> },
            { path: 'view/:id', element: <TestimonialDetail /> },
          ],
        },
        {
          path: 'events',
          children: [
            {
              element: <Navigate to="/dashboard/events/list" replace />,
              index: true,
            },
            { path: 'list', element: <EventList /> },
            { path: 'new', element: <EventCreate /> },
            { path: ':id/edit', element: <EventCreate /> },
            { path: 'view/:id', element: <EventDetail /> },
          ],
        },

        {
          path: 'inviteuser',
          children: [
            {
              element: <Navigate to="/inviteusers/list" replace />,
              index: true,
            },
            { path: 'list', element: <InviteUsers /> },
          ],
        },
        // Notification
        {
          path: 'notification',
          children: [
            {
              element: <Navigate to="/notification/list" replace />,
              index: true,
            },
            { path: 'list', element: <Notification /> },
          ],
        },
        // Enquiry Email
        {
          path: 'enquiryemail',
          children: [
            {
              element: <Navigate to="/enquiryemail/list" replace />,
              index: true,
            },
            { path: 'list', element: <EnquiryEmail /> },
            { path: ':id/edit', element: <EditEnquirypage /> },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// CLASS
const ClassList = Loadable(lazy(() => import('../pages/dashboard/Classes/ClassList')));
const ClassCreate = Loadable(lazy(() => import('../pages/dashboard/Classes/ClassCreate')));
const ClassDetail = Loadable(lazy(() => import('../pages/dashboard/Classes/ClassDetail')));

// SHOP
const ShopList = Loadable(lazy(() => import('../pages/dashboard/Shops/ShopList')));
const ShopCreate = Loadable(lazy(() => import('../pages/dashboard/Shops/ShopCreate')));
const ShopDetail = Loadable(lazy(() => import('../pages/dashboard/Shops/ShopDetail')));

// TESTIMONIAL
const TestimonialList = Loadable(
  lazy(() => import('../pages/dashboard/Testimonials/TestimonialList'))
);
const TestimonialCreate = Loadable(
  lazy(() => import('../pages/dashboard/Testimonials/TestimonialCreate'))
);
const TestimonialDetail = Loadable(
  lazy(() => import('../pages/dashboard/Testimonials/TestimonialDetail'))
);

// EVENT
const EventList = Loadable(lazy(() => import('../pages/dashboard/Events/EventList')));
const EventCreate = Loadable(lazy(() => import('../pages/dashboard/Events/EventCreate')));
const EventDetail = Loadable(lazy(() => import('../pages/dashboard/Events/EventDetail')));

// Invite User
const InviteUsers = Loadable(lazy(() => import('../pages/dashboard/InviteUsers/InviteUsers')));

// SEND NOTIFICATION
const Notification = Loadable(
  lazy(() => import('../pages/dashboard/SendNotification/SendNotification'))
);

//  ENQUIRYEMAIL
const EnquiryEmail = Loadable(lazy(() => import('src/pages/dashboard/EnquiryEmail/EnquiryEmail')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// TEST RENDER PAGE BY ROLE

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
