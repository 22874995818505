import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

import AllReducer from './slices/calendar';
// import AllUser from './slices/allUser';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  Alluser: AllReducer,
});

export { rootPersistConfig, rootReducer };
