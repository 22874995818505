// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, 'login'),

  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/Userlist'),

    edit: (id: any) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  // -------------------------------------------------------------------------------------------------
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },

  class: {
    root: path(ROOTS_DASHBOARD, '/class'),
    list: path(ROOTS_DASHBOARD, '/class/list'),
    new: path(ROOTS_DASHBOARD, '/class/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/class/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/class/view/${id}`),
    demoView: path(ROOTS_DASHBOARD, '/class/post/apply-these-7-secret-techniques-to-improve-event'),
  },

  shop: {
    root: path(ROOTS_DASHBOARD, '/shop'),
    list: path(ROOTS_DASHBOARD, '/shop/list'),
    new: path(ROOTS_DASHBOARD, '/shop/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/shop/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/shop/view/${id}`),
    demoView: path(ROOTS_DASHBOARD, '/shop/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  Testimonials: {
    root: path(ROOTS_DASHBOARD, '/testimonials'),
    list: path(ROOTS_DASHBOARD, '/testimonials/list'),
    new: path(ROOTS_DASHBOARD, '/testimonials/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/testimonials/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/testimonials/view/${id}`),
    demoView: path(
      ROOTS_DASHBOARD,
      '/testimonials/post/apply-these-7-secret-techniques-to-improve-event'
    ),
  },
  Event: {
    root: path(ROOTS_DASHBOARD, '/events'),
    list: path(ROOTS_DASHBOARD, '/events/list'),
    new: path(ROOTS_DASHBOARD, '/events/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/events/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/events/view/${id}`),
    demoView: path(
      ROOTS_DASHBOARD,
      '/events/post/apply-these-7-secret-techniques-to-improve-event'
    ),
  },
  InviteUser: {
    root: path(ROOTS_DASHBOARD, '/inviteuser'),
    list: path(ROOTS_DASHBOARD, '/inviteuser/list'),
  },
  Notification: {
    root: path(ROOTS_DASHBOARD, '/notification'),
    list: path(ROOTS_DASHBOARD, '/notification/list'),
  },
  enquiryemail: {
    root: path(ROOTS_DASHBOARD, '/enquiryemail'),
    list: path(ROOTS_DASHBOARD, '/enquiryemail/list'),
    editEnquiryemail: (id: any) => path(ROOTS_DASHBOARD, `/enquiryemail/${id}/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
