// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { FormHelperText } from "@mui/material";
//
import Editor, { Props as EditorProps } from "../editor";

// ----------------------------------------------------------------------

interface Props extends EditorProps {
  name: string;
}

export default function InviteRHFEditor({ name, ...other }: Props) {
  const { control } = useFormContext();

  const defaultMessage = ` <div>  <p>Hii,</p> <br/> <p>  We’ve given you access to our Afrofit App so that you can manage your journey with us and  get to know all the possibilities offered by Afrofit service     </p><p>   If you want to create an account, please click on the following link:   <a href=https://afrofitapp.com/
  >GET STARTED!</a></p>   <p>Enjoy!</p> <br/> <p> Thanks   <br/>  The Afrofit App team   </p> </div>`;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Editor
          id={name}
          value={field?.value ? field?.value : defaultMessage}
          onChange={field.onChange}
          error={!!error}
          helperText={
            <FormHelperText error sx={{ px: 2, textTransform: "capitalize" }}>
              {error?.message}
            </FormHelperText>
          }
          {...other}
        />
      )}
    />
  );
}
